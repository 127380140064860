import Select, { SelectProps } from "antd/lib/select";
import cc from "classcat";
import React from "react";

import { ReactComponent as DownOutlined } from "../../../assets/icons/downOutlined.svg";
import cm from "../style.module.scss";

export interface RetailSelectProps extends SelectProps {}

const RetailSelect: React.FC<RetailSelectProps> = ({ children, className, dropdownClassName, ...selectProps }) => {
  return (
    <Select
    className={cc([cm.select, className || ""])} 
    dropdownClassName={cc([cm.dropdown, dropdownClassName || ""])}
    suffixIcon={<DownOutlined />}
    {...selectProps}>
      {children}
    </Select>
  );
};

export default RetailSelect;
