import {
	Route,
	Routes,
} from "react-router-dom";

import AdPlacementPage from "../../../pages/admin/AdPlacementPage";
import AdvertiserSettingsPage from "../../../pages/admin/AdvertiserSettingsPage";
import AdvertisersPage from "../../../pages/admin/AdvertisersPage";
import AgenciesPage from "../../../pages/admin/AgenciesPage";
import AuctionSettingsPage from "../../../pages/admin/AuctionSettingsPage";
import CampaignSettingsPage from "../../../pages/admin/CampaignSettingsPage";
import CouponsPage from "../../../pages/admin/CouponsPage";
import CreativesPage from "../../../pages/admin/CreativesPage";
import GeneralInformationPage from "../../../pages/admin/GeneralInformationPage";
import InspectPage from "../../../pages/admin/InspectPage";
import IntegrationInformationPage from "../../../pages/admin/IntegrationInformationPage";
import InvitedPage from "../../../pages/admin/InvitedPage";
import RestrictionsPage from "../../../pages/admin/RestrictionsPage";
import SynchronizationHistoryPage from "../../../pages/admin/SynchronizationHistoryPage";
import TransactionsPage from "../../../pages/admin/TransactionsPage";
import UsersPage from "../../../pages/admin/UsersPage";
import CampaignsPage from "../../../pages/shared/CampaignsPage";
import CreateCampaignPage from "../../../pages/shared/CreateCampaignPage";
import CreateReportPage from "../../../pages/shared/CreateReportPage";
import EditReportPage from "../../../pages/shared/EditReportPage";
import NoMatch from "../../../pages/shared/NoMatch";
import ReportDetailsPage from "../../../pages/shared/ReportDetailsPage";
import ReportsPage from "../../../pages/shared/ReportsPage";

const RetailAdminRoutes = () => {
  return (
    <Routes>
      {["/", "admin/campaigns", "admin",].map((path, index) => (
        <Route path={path} element={<CampaignsPage />} key={index} />
      ))}
      <Route path="admin/create-campaign" element={<CreateCampaignPage />} />
      <Route path="admin/advertisers" element={<AdvertisersPage />} />
      <Route path="admin/agencies" element={<AgenciesPage />} />
      <Route path="admin/placement" element={<AdPlacementPage />} />
      <Route path="admin/creatives" element={<CreativesPage />} />
      <Route path="admin/review" element={<InspectPage />} />
      <Route path="admin/transactions" element={<TransactionsPage />} />
      <Route path="admin/coupons" element={<CouponsPage />} />
      <Route path="admin/users" element={<UsersPage />} />
      <Route path="admin/invited" element={<InvitedPage />} />
      <Route
        path="admin/synchronization"
        element={<SynchronizationHistoryPage />}
      />
      <Route path="admin/reports" element={<ReportsPage />} />
      <Route path="admin/create-report" element={<CreateReportPage />} />
      <Route path="admin/edit-report/:id" element={<EditReportPage />} />
      <Route path="admin/report/:id" element={<ReportDetailsPage />} />
      <Route path="admin/general" element={<GeneralInformationPage />} />
      <Route
        path="admin/integration"
        element={<IntegrationInformationPage />}
      />
      <Route path="admin/advertiser" element={<AdvertiserSettingsPage />} />
      <Route path="admin/auction" element={<AuctionSettingsPage />} />
      <Route path="admin/single" element={<CampaignSettingsPage />} />
      <Route path="admin/restrictions" element={<RestrictionsPage />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default RetailAdminRoutes;
