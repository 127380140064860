import { numFormatter } from "../../../utils/helpers";

export interface RetailNumberColumnProps {
  value: number;
}
const RetailNumberColumn = ({ value }: RetailNumberColumnProps) => {
  return <span>{value ? numFormatter(value) : 0}</span>;
};

export default RetailNumberColumn;
