import RetailAuthView from "./RetailAuthView";
import cm from "./style.module.scss";

export interface RetailAuthLayoutProps {
  children: React.ReactNode;
}

const RetailAuthLayout = ({ children }: RetailAuthLayoutProps) => {
  const bg = (window as any).marketplaceConfig.background_type?.toLowerCase() || "light";
  return (
    <section className={cm.container}>
      <RetailAuthView />
      {children}
      {bg === "light" && <div className={cm.top}></div>}
    </section>
  );
};

export default RetailAuthLayout;
