export const monthly = (t: any) => [
  {
    value: "FIRST_DAY",
    label: t("components.reportForm.firstDay"),
  },
  {
    value: "LAST_DAY",
    label: t("components.reportForm.lastDay"),
  },
];

export const frequency = (t: any) => [
  {
    value: "DAILY",
    label: t("components.reportForm.daily"),
  },
  {
    value: "WEEKLY",
    label: t("components.reportForm.weekly"),
  },
  {
    value: "MONTHLY",
    label: t("components.reportForm.monthly"),
  },
];

export const fileFormats = [
  {
    value: "CSV",
    label: ".CSV",
  },
  {
    value: "PDF",
    label: ".PDF",
  },
  {
    value: "EXCEL",
    label: "EXCEL",
  },
];

export const predefinedTypes = (t: any) => [
  {
    value: "PRODUCT",
    label: t("components.reportForm.product"),
  },
  {
    value: "PRODUCT_CATEGORY",
    label: t("components.reportForm.product_category"),
  },
  {
    value: "PRODUCT_BRAND",
    label: t("components.reportForm.product_brand"),
  },
  {
    value: "KEYWORD",
    label: t("components.reportForm.keyword"),
  },
  {
    value: "CAMPAIGN",
    label: t("components.reportForm.campaign"),
  },
];

export const adminPredefinedTypes = (t: any) => [
  ...predefinedTypes(t),
  {
    value: "ADVERTISER",
    label: t("components.reportForm.advertiser"),
  },
  {
    value: "AGENCY",
    label: t("components.reportForm.agency"),
  },
];
