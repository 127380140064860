import { ReactComponent as AdFilled } from "../../../../../assets/icons/menu/adFilled.svg";
import { ReactComponent as AdOutlined } from "../../../../../assets/icons/menu/adOutlined.svg";
import { ReactComponent as AdvertisersFilled } from "../../../../../assets/icons/menu/advertisersFilled.svg";
import { ReactComponent as AdvertisersOutlined } from "../../../../../assets/icons/menu/advertisersOutlined.svg";
import { ReactComponent as AgenciesFilled } from "../../../../../assets/icons/menu/agenciesFilled.svg";
import { ReactComponent as AgenciesOutlined } from "../../../../../assets/icons/menu/agenciesOutlined.svg";
import { ReactComponent as BarsFilled } from "../../../../../assets/icons/menu/barsFilled.svg";
import { ReactComponent as BarsOutlined } from "../../../../../assets/icons/menu/barsOutlined.svg";
import { ReactComponent as CampaignFilled } from "../../../../../assets/icons/menu/campaignFilled.svg";
import { ReactComponent as CampaignOutlined } from "../../../../../assets/icons/menu/campaignOutlined.svg";
import { ReactComponent as FinanceFilled } from "../../../../../assets/icons/menu/financeFilled.svg";
import { ReactComponent as FinanceOutlined } from "../../../../../assets/icons/menu/financeOutlined.svg";
import { ReactComponent as PlacementFilled } from "../../../../../assets/icons/menu/placementFilled.svg";
import { ReactComponent as PlacementOutlined } from "../../../../../assets/icons/menu/placementOutlined.svg";
import { ReactComponent as SettingsFilled } from "../../../../../assets/icons/menu/settingsFilled.svg";
import { ReactComponent as SettingsOutlined } from "../../../../../assets/icons/menu/settingsOutlined.svg";
import { ReactComponent as UsersFilled } from "../../../../../assets/icons/menu/usersFilled.svg";
import { ReactComponent as UsersOutlined } from "../../../../../assets/icons/menu/usersOutlined.svg";

export const retailAdminRouteObject = [
  {
    link: "/admin/agencies",
    key: "agencies",
    iconFilled: <AgenciesFilled />,
    iconOutlined: <AgenciesOutlined />,
  },
  {
    link: "/admin/advertisers",
    key: "advertisers",
    iconFilled: <AdvertisersFilled />,
    iconOutlined: <AdvertisersOutlined />,
  },
  {
    link: "/admin/campaigns",
    key: "campaigns",
    iconFilled: <CampaignFilled />,
    iconOutlined: <CampaignOutlined />,
    sub: false,
  },
  {
    link: "/admin/placement",
    key: "placement",
    iconFilled: <PlacementFilled />,
    iconOutlined: <PlacementOutlined />,
  },
  {
    key: "ad",
    iconFilled: <AdFilled />,
    iconOutlined: <AdOutlined />,
    sub: true,
    breadcrumb: false,
    children: [
      {
        key: "review",
        link: "/admin/review",
      },
      {
        key: "creatives",
        link: "/admin/creatives",
      },
    ],
  },
  {
    key: "finance",
    iconFilled: <FinanceFilled />,
    iconOutlined: <FinanceOutlined />,
    sub: true,
    breadcrumb: true,
    children: [
      {
        key: "transactions",
        link: "/admin/transactions",
      },
      {
        key: "coupons",
        link: "/admin/coupons",
      },
    ],
  },
  {
    key: "access",
    iconFilled: <UsersFilled />,
    iconOutlined: <UsersOutlined />,
    sub: true,
    breadcrumb: true,
    children: [
      {
        key: "users",
        link: "/admin/users",
      },
      {
        key: "invited",
        link: "/admin/invited",
      },
    ],
  },
  /* {
    link: "/admin/activity",
    key: "activity",
    iconFilled: <ChangesFilled />,
    iconOutlined: <ChangesOutlined />,
  }, */
  {
    link: "/admin/reports",
    key: "reports",
    iconFilled: <BarsFilled />,
    iconOutlined: <BarsOutlined />,
  },
  {
    key: "settings",
    iconFilled: <SettingsFilled />,
    iconOutlined: <SettingsOutlined />,
    sub: true,
    breadcrumb: true,
    children: [
      {
        key: "general",
        link: "/admin/general",
      },
      {
        key: "integration",
        link: "/admin/integration",
      },
      {
        key: "advertiser",
        link: "/admin/advertiser",
      },
      {
        key: "auction",
        link: "/admin/auction",
      },
      {
        key: "single",
        link: "/admin/single",
      },
      {
        key: "restrictions",
        link: "/admin/restrictions",
      },
    ],
  },
];
